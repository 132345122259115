import React, { useContext, useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { UserContext } from "../../index";
import { check, changePasswordHttp } from '../../http/controllers'; // Предполагается, что у вас есть функция changePassword
import Button from '../../components/buttons/Button';
import { Store } from 'react-notifications-component';

const ChangePassword = () => {

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    // const user = useContext(UserContext);



    // useEffect(() => {

    //     const body = document.getElementsByTagName("body");

    //     check().then((data) => {
    //         user.setIsAuth(true);
    //     })
    //         .catch((e) => {
    //             user.setIsAuth(false);
    //             body[0].style.overflow = "hidden";
    //         }).finally(() => {
    //             body[0].style.overflow = "unset";
    //         });
    //     //eslint-disable-next-line
    // }, []);


    const onSubmitChangePassword = async (e) => {
        try {
            const response = await changePasswordHttp({ currentPassword, newPassword });
            if (response) {
                // Обработка успешной смены пароля
                e.target.disabled = true;
                e.target.style.background = '#b4b4b4';
                Store.addNotification({
                    title: "Успех!",
                    message: "Пароль успешно изменен!",
                    type: "success",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            // Обработка ошибок при смене пароля

            Store.addNotification({
                title: "Ошибка!",
                message: "Недопустимый пароль",
                type: "danger",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }
    };


    return (
        <>
            <Header />
            <div className="changePassword">
                <div className='container'>
                    <div key={'id'} className="formWrappLogin">
                        <h2 className="form__question">Смена пароля</h2>
                        <div className="loginForm">
                            <input
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                placeholder="Текущий пароль"
                                type='password'
                                name='currentPassword'
                                id='currentPassword'
                            />
                            <input
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Новый пароль"
                                type='password'
                                name='newPassword'
                                id='newPassword'
                            />
                            <Button
                                onClick={onSubmitChangePassword}
                                addClass="buttonForward"
                                title="Сменить пароль"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ChangePassword;
