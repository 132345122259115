import { useParams, useLocation } from "react-router-dom"
import Footer from "../../components/footer/Footer"
// import Form from "../../components/form/Form"
import Header from "../../components/header/Header"
import FormView from "../../components/form/formView/FormView"
import useToken from "../../hooks/useToken"
import { getCurrentForm, getQuestionairById, sendDataForm } from "../../http/controllers"
import { useEffect, useState } from "react"
import goToNextQuestion from "../../utils/goToNextQuestion"
import endForm from "../../utils/endForm"
import handleInputChange from "../../utils/handleInputChange"
import "../../components/form/form.scss";

///!!!!!!!!!!!!!!!!!!!!!
const FormPage = () => {
    const { token } = useParams('token');
    const location = useLocation();
    const state = location.state || {}

    const [data, setData] = useState(null);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [questionnaire, setQuestionare] = useState(null);
    // const [questionnaire, setQuestionare] = useState(null);
    let pool = data && data.pool;

    //eslint-disable-next-line 
    const [currentForm, setCurrentForm] = useState(location.state !== null ? location.state.formId : pool)

    const [formData, setFormData] = useState([]);
    // Состояние для хранения выбранного значения радио-кнопки
    const [selectedRadioValue, setSelectedRadioValue] = useState(null);


    useToken(getCurrentForm, token, setQuestionare, setData, setCurrentQuestionId, getQuestionairById, currentForm, state.isTest);

    // const postData = async (data = {}) => {
    //     // Опции для запроса
    //     data = {
    //         'answers': data,
    //         'pool': currentForm ? currentForm : pool
    //     }
    //     sendDataForm(data).catch((error) => {
    //         console.log(`HTTP error! status: ${error.status}`)
    //     })
    // };


    const postData = async (data = {}) => {

        const updateAnswerNames = (answers) => {
            return answers.map(answer => {
                if (answer.name.length > 100) {
                    return {
                        ...answer,
                        name: answer.name.substring(0, 96) + '...'
                    };
                }
                return answer;
            });
        };

        // Опции для запроса
        data = {
            'answers': updateAnswerNames(data),
            'pool': currentForm ? currentForm : pool
        }
        sendDataForm(data).catch((error) => {
            console.log(`HTTP error! status: ${error.status}`)
        })
    };

    console.log("formData:", formData)
    console.log("data:", data);

    const currentQuestion = data && data.points ? data.points.find((q) => q.id === currentQuestionId) : null;

    useEffect(() => {
        goToNextQuestion(currentQuestion, formData, setCurrentQuestionId, endForm, postData, data)
        // eslint-disable-next-line
    }, [formData])


    // Функция для обработки изменения состояния радио-кнопки
    const handleRadioChange = (event) => {
        setSelectedRadioValue(event.target.value);
    };

    //_______________________________________    


    return (
        <>
            <Header department={data} />
            <FormView
                questionnaire={questionnaire}
                currentQuestion={currentQuestion}
                data={data}
                selectedRadioValue={selectedRadioValue}
                handleRadioChange={handleRadioChange}
                handleInputChange={handleInputChange}
                setFormData={setFormData}
                goToNextQuestion={goToNextQuestion} />
            {/* <Form token={token} isTest={state.isTest} /> */}
            <Footer />
        </>
    )


}

export default FormPage