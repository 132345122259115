import './styles.scss'
import AdminTable from '../../components/AdminTable'
import Header from '../../components/header/Header'
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { deletePoint, getQuestionairById, getQuestionairByIdAuth } from '../../http/controllers';
import FormFields from './components/formFields';
import { UserContext } from '../..';
import Footer from '../../components/footer/Footer';
import AdminButton from '../../components/AdminButton/AdminButton';
import DeleteButton from '../../components/markerButtons/deleteButton';
import EditButton from '../../components/markerButtons/editButton';
import { Store } from 'react-notifications-component';


const RedactorForm = () => {
    let user = useContext(UserContext)
    const navigate = useNavigate();
    let [isLogout, setIsLogout] = useState(false)

    const logout = () => {
        user.logout()
        setIsLogout(!isLogout)
        navigate('/')
    }

    const location = useLocation();
    const formId = (location.state) ? location.state.formId : 0
    const [currentForm, setCurrentForm] = useState({})
    const [rerender, setRerender] = useState(true)
    // let rerender = true
    // Начальные данные для отображения таблицы
    let headers = ['№ вопроса', 'Название вопроса', 'Тип вопроса', 'Действия']
    let columnMap = '1fr 7fr 2fr 2fr '
    let [data, setData] = useState([])

    useEffect(() => {
        if (formId) {
            // достаем данные формы, если вызван запрос на ее изменение
            getQuestionairByIdAuth(formId).then((data) => {
                data.points = data.points.sort((a, b) => a.number - b.number)
                return data
            }).then((data) => {
                setCurrentForm(data)
                setData(pointsConvertData(data.points))
            }).catch((e) => console.log('ERROR: ', e.message))
        }
    }, [rerender])

    // Обработчик события на кнопку редактирования вопроса
    const onEditPoint = (id) => {
        navigate('/redactorPoint/' + id, { state: { formId: formId, formName: currentForm.name } })
    }

    // Обработчик события на кнопку удаления вопроса
    const onDeletePoint = (id) => {
        if (window.confirm('Вы уверены, что хотите удалить вопрос? Все альтернативные пути формы будут утеряны'))
            deletePoint(id).then((response) => {
                setRerender(!rerender)
                onDeleteSuccess();
            })
    }

    // функция для преобразования данных, приходящих в запросе в данные для отображения в таблицу
    const pointsConvertData = (points) => {
        let data = []
        points.forEach((point) => {
            data.push([
                point.number,
                point.name,
                point.type_point_description,
                <>
                    <EditButton onClick={() => onEditPoint(point.id)} />
                    <DeleteButton onClick={() => onDeletePoint(point.id)} />
                </>
            ])
        })
        return data;
    }

    const onDeleteSuccess = () => {
        Store.addNotification({
            title: "Успех!",
            message: "Вопрос удален",
            type: "success",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }

    return (
        <>
            <Header logout={logout} />
            <div className='breadcrumbs'>
                <span onClick={() => navigate('/admin')}>Формы</span> /
                <span >{currentForm.name}</span>
            </div>
            <div className='container' >
                <div key={'id'} className="admin__content__zone">
                    <div className='redactor__page__title'>
                        <h2 className=" "> {(formId) ? currentForm.name : 'Новая форма'}</h2>
                    </div>

                    <FormFields form={currentForm} />

                    {formId ? <>
                        <AdminTable columnMap={columnMap} headers={headers} data={data} />
                        <div className='buttons__under__forms'>
                            <AdminButton
                                onClick={(e) => {
                                    navigate('/redactorPoint', { state: { formId: formId, formName: currentForm.name } })
                                }}
                                text={<><div>СОЗДАТЬ ВОПРОС</div><div>&#62;&#62;</div></>}
                            />
                        </div>
                    </> : ''}
                </div>
            </div>
            <Footer />
        </>
    )

}

export default RedactorForm