import './styles.scss'

const EditButton = (
    props
)=>{
    return (
        <div className='markButton editeButton' {...props}></div>
    )
}

export default EditButton