

const SelectAlternatives = ({
    points,
    alternative,
    id,
    ...props
}) => {
    let names = {}
    points.forEach(point => {
        let strs = point.name.split(' ')
        let name = ''
        let i = 0
        while (name.length < 50 && strs.length > i) {
            name += strs[i] + ' '
            i++
        }
        if (strs.length > i) name += '...'
        names[point.id] = name
    });

    return (
        <>
            <select name={"alternative_" + id} {...props}>
                <option value={-1}>нет</option>
                {points.map((point)=> <option value={point.id} selected={(alternative && alternative.target == point.id)? 'selected' : ''}>{point.number} {names[point.id] }</option> )}
            </select >
        </>
    )
}   

export default SelectAlternatives