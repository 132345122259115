// datA === datE
const downloadDoc = (id, downloadReport, data) => {
    downloadReport(id, data).then((data1) => {
        const href = URL.createObjectURL(data1);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'report.xls');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    })
}

// const downloadDoc = (id, downloadReport, date) => {
//     downloadReport(id, date).then((blob) => {
//         const href = URL.createObjectURL(blob); // Создаем URL для Blob
//         const link = document.createElement('a');
//         link.href = href;
//         link.setAttribute('download', 'report.xls'); // Указываем имя файла для скачивания
//         document.body.appendChild(link);
//         link.click(); // Имитируем клик, чтобы начать скачивание
//         document.body.removeChild(link); // Удаляем элемент ссылки
//         URL.revokeObjectURL(href); // Освобождаем URL
//     }).catch(error => {
//         console.error('Ошибка при скачивании отчета:', error);
//     });
// }

export default downloadDoc;