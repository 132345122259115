import "./styles.scss";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import AdminButton from "../../../../components/AdminButton/AdminButton"
import { useContext, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { createNewForm, downloadReport, editForm, getLink } from "../../../../http/controllers";
import { UserContext } from "../../../..";
import downloadDoc from "../../../../utils/downloadDoc";

const FormFields = ({
    form,
    ...props
}) => {

    let formHtml = useRef()
    const navigate = useNavigate();
    let user = useContext(UserContext)
    const [errMessage, setErrMessage] = useState(<></>)

    const onSaveForm = () => {
        let formData = new FormData(formHtml.current)
        if (form.id) {
            // formData.append('id', form.id)
            editForm(formData, form.id).then((data) => {
                navigate('/redactorform', {
                    state: {
                        formId: data.id
                    }
                })
            })
            onCreateFormSuccess()
        } else {
            let isValid = true
            formData.forEach((val) => {
                if (val == '') isValid = false
            })
            if (isValid)
                createNewForm(formData).then((data) => {
                    navigate('/redactorform', {
                        state: {
                            formId: data.id
                        }
                    })
                })
            else setErrMessage(<div style={{ color: 'red' }} >Поля формы заполнены неверно</div>)
        }
    }

    // const [isCopyLink, setIsCopyLink] = useState(false)
    const [linkHtml, setLinkHtml] = useState(<></>)
    const onGetLink = (e, id) => {
        if (id) {
            getLink(id).then(data => {
                if (data.link) {

                    let link = 'http://' + window.location.host + data.link

                    let style = {
                        top: (e.pageY + 20) + 'px',
                        left: (e.pageX + 20) + 'px'
                    }
                    let linkHtml_ = <div className="copyLink" style={style}>
                        <input value={link} id="copyLink"></input>
                        <div onClick={() => {
                            document.getElementById('copyLink').select()
                            document.execCommand('copy');
                            onCopySuccess();
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                            </svg>
                        </div>
                        <div onClick={() => { setLinkHtml(<></>) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                            </svg>
                        </div>
                    </div>
                    setLinkHtml(linkHtml_)

                } else console.log('ссылка пуста')

            })
        }
    }

    const onCopySuccess = () => {
        Store.addNotification({
            title: "Успех!",
            message: "Успешно скопировано",
            type: "success",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2000,
                onScreen: true
            }
        });
    }


    const onCreateFormSuccess = () => {
        Store.addNotification({
            title: "Успех!",
            message: "Изменения сохранены",
            type: "success",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2000,
                onScreen: true
            }
        });
    }

    return (
        <>
            {linkHtml}
            <form ref={formHtml}>
                <div className="formField">
                    <div className="formField__oneRow">
                        <div className="fieldName">Название формы</div>
                        <div>
                            <input name='name' placeholder="наименование" defaultValue={(form) ? form.name : ''} />
                        </div>
                    </div>
                    <div className="fieldName">Статусы: </div>
                    <div className="formField__twoSelect">
                        <div>
                            <select name='published'>
                                <option value={0} selected={(!form.published) ? 'selected' : ''}>Черновик</option>
                                <option value={1} selected={(form.published) ? 'selected' : ''}>Опубликовано</option>
                            </select>
                        </div>
                        <div>
                            <select name='finished'>
                                <option value={0} selected={(!form.finished) ? 'selected' : ''}>Открыт</option>
                                <option value={1} selected={(form.finished) ? 'selected' : ''}>Завершен</option>
                            </select>
                        </div>
                    </div>
                    <div className="formField__oneRow">
                        <div className="fieldName">Дата запуска</div>
                        <div>
                            <input name='start_date' type="date" defaultValue={(form) ? form.start_date : new Date} />
                        </div>
                    </div>
                    <div className="formField__oneRow">
                        <div className="fieldName">Дата окончания</div>
                        <div>
                            <input name='finish_date' type="date" defaultValue={(form) ? form.finish_date : new Date} />
                        </div>
                    </div>
                    {errMessage}
                    <div className='formField__buttons'>
                        {form.id ? <>
                            <AdminButton
                                classes={'btn_new_q_on_q'}
                                onClick={(e) => downloadDoc(form.id, downloadReport)}
                                text={<><div>ОТЧЕТ</div></>}
                            />
                            <AdminButton
                                classes={'btn_new_q_on_q'}
                                onClick={(e) => navigate('/form', { state: { formId: form.id, isTest: true } })}
                                text={<><div>ПЕРЕЙТИ К ФОРМЕ ОПРОСА</div></>}
                            />
                            <AdminButton
                                classes={'btn_new_q_on_q'}
                                onClick={(e) => onGetLink(e, form.id)}
                                text={<><div>СОЗДАТЬ ВРЕМЕННУЮ ССЫЛКУ</div></>}
                            />
                        </>
                            : ''
                        }

                        <AdminButton
                            onClick={onSaveForm}
                            text={<><div>СОХРАНИТЬ</div></>}
                        />
                    </div>
                </div>
            </form>
        </>
    )

}

export default FormFields