import { useEffect, useRef, useState } from 'react'
import SelectAlternatives from '../SelectAlternatives'
import TextboxAndAlternatives from '../TextboxAndAlternatives/input'
import './styles.scss'
import AltpointMark from '../AltPointInts/AltpointMark'
import TextboxQuestion from '../TextboxQuestion/input'

const QuestionsFieldsMark = ({
    point,
    points,
    isEditForm,
    ...props
}) => {
    const container = useRef()
    let [fields, setFields] = useState([])
    const [alternative, setAlternative] = useState([])
    let [headers, setHeaders] = useState([])



    useEffect(() => {
        if (point && point.questions) {
            let newArrFields = []
            let id = -1
            point.questions.forEach((q) => {
                id = (q) ? q.id : performance.now();
                newArrFields.push({
                    template: isEditForm ? <TextboxQuestion isEditForm={isEditForm} question={q} /> : <div className='q__field__margin'>{q.name}</div>

                })
            })
            setFields(newArrFields)
        }

    }, [isEditForm])

    const addQuestion = () => {
        setFields([...fields, { template: <TextboxQuestion isEditForm={isEditForm} /> }])
    }


    return (
        <>
            <div>
                <div className='questionFields__container'>
                    <div className="fieldName">Варианты ответов</div>
                    <div className="fieldName">Переход</div>
                </div>
                <div className='questionFields__container'>
                    <div >
                        {fields.map((q) => q.template)}
                    </div>
                    <AltpointMark point={point} points={points} isEditForm={isEditForm} />
                </div>
                {isEditForm ? <div className='btn__adQduestion' onClick={addQuestion}> + </div> : ''}

            </div>
        </>
    )
}

export default QuestionsFieldsMark