import './styles.scss'

import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import { authorization, check, editForm, getQuestionairAll, getQuestionairAllAuth, getQuestionairById, getQuestionairByIdAuth } from '../../http/controllers'
import { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../..'
import { useNavigate } from "react-router-dom";
import AdminTable from '../../components/AdminTable'
import CompleteButton from '../../components/markerButtons/completeButton'
import NoCompleteButton from '../../components/markerButtons/noCompleteButton'
import AdminButton from '../../components/AdminButton/AdminButton'
import EditButton from '../../components/markerButtons/editButton'
import DeleteButton from '../../components/markerButtons/deleteButton'
import { observer } from 'mobx-react'
import { userStore } from '../../store/user'
import { headerStore } from '../../store/headerStore'

const AdminPanelForms = observer(() => {
    let user = useContext(UserContext)
    const navigate = useNavigate();
    let [isLogout, setIsLogout] = useState(false)
    let page = useRef()

    const logout = () => {
        user.logout()
        setIsLogout(!isLogout)
        navigate('/')
    }

    // Начальные данные для отображения таблицы
    let headers = ['Номер', 'Название', 'Завершен', 'Публикация', 'Действия']
    let columnMap = '1fr 5fr 1fr 1fr 2fr'
    const [data, setData] = useState([])

    // Обработчик события на кнопку создания формы
    const onCreateForm = (id) => {
        navigate('/redactorform')
    }

    // Обработчик события на кнопку редактирования формы
    const onEditForm = (id) => {
        navigate('/redactorform', { state: { formId: id } })
    }

    // Обработчик события на кнопку удаления формы
    const onDeleteForm = (id) => {
        alert('вы уверены, что хотите удалить форму?' + id)
    }

    // Обработчик события на кнопку публиквции формы по клику правой клавишей мыши
    const onChangePublish = (e, id) => {

        e.preventDefault()

        // удаляем предыдущие открытые селекты
        Array.prototype.slice.call(document.getElementsByClassName('appearSelect')).forEach(el => el.remove())

        // Создаем селект
        let select = document.createElement('select');
        select.classList.add('appearSelect')
        select.id = 'absolute'
        select.style.position = 'absolute'
        select.style.padding = '.5rem'
        select.style.background = '#eef'
        select.style.border = '1px solid #eee'
        // Размещаем созданный селект возле места клика мышью
        select.style.top = e.pageY + 'px'
        select.style.left = e.pageX + 'px'
        // Добавление содержимого селекта
        let option = document.createElement('option')
        option.setAttribute('value', '0')
        option.innerHTML = 'не выбрано'
        select.append(option)
        option = document.createElement('option')
        option.setAttribute('value', '1')
        option.innerHTML = 'снять с публикации'
        select.append(option)
        option = document.createElement('option')
        option.setAttribute('value', '2')
        option.innerHTML = 'опубликовать'
        select.append(option)

        // Обработчик на удаление селекта после выбора значения
        select.addEventListener('focusout', () => {
            select.remove()
        })

        // обработчик выбора дейтвия
        select.addEventListener('change', (e) => {
            getQuestionairByIdAuth(id).then(data => {
                if (e.target.value == 1 && ('Вы уверены, что хотите снять опрос с публикации?')) {
                    data.published = false
                    editForm(data, data.id).then(data => {

                        navigate(0)
                    })
                } else if (e.target.value == 2 && window.confirm('Вы уверены, что хотите запустить опубликовать опрос?')) {
                    data.published = true
                    editForm(data, data.id).then(data => {

                        navigate(0)
                    })
                }
            })
            e.target.blur()
        })
        select.focus()
        // добавляем селект внутри страницы, чтобы при переходе на другую он исчезал вместе с ней
        page.current.append(select)
    }

    // Функция аналогичная публикациям (см. выше) для закрытия (завершения) формы
    const onChangeFinish = (e, id) => {

        e.preventDefault()
        Array.prototype.slice.call(document.getElementsByClassName('appearSelect')).forEach(el => el.remove())


        let select = document.createElement('select');
        select.classList.add('appearSelect')
        select.style.position = 'absolute'
        select.style.padding = '.5rem'
        select.style.background = '#eef'
        select.style.border = '1px solid #eee'
        select.style.top = e.pageY + 'px'
        select.style.left = e.pageX + 'px'
        let option = document.createElement('option')
        option.setAttribute('value', '0')
        option.innerHTML = 'не выбрано'
        select.append(option)
        option = document.createElement('option')
        option.setAttribute('value', '1')
        option.innerHTML = 'завершить'
        select.append(option)
        option = document.createElement('option')
        option.setAttribute('value', '2')
        option.innerHTML = 'запустить'
        select.append(option)
        select.addEventListener('focusout', () => {
            select.remove()
        })
        select.addEventListener('change', (e) => {
            getQuestionairByIdAuth(id).then(data => {
                if (e.target.value == 1 && window.confirm('Вы уверены, что хотите завершить опрос?')) {
                    data.finished = true
                    editForm(data, data.id).then(data => {
                        // alert('снято с публиации')
                        navigate(0)
                    })
                } else if (e.target.value == 2 && window.confirm('Вы уверены, что хотите запустить опрос?')) {
                    data.finished = false
                    editForm(data, data.id).then(data => {
                        // alert('снято с публиации')
                        navigate(0)
                    })
                }
            })
            e.target.blur()
        })
        select.focus()
        page.current.append(select)
    }

    // функция для преобразования данных, приходящих в запросе в данные для отображения в таблицу
    const formsConvertData = (forms) => {
        let data = []
        let i = 1
        forms.forEach((form) => {
            data.push([
                i,
                form.name,
                (form.finished == true) ? <CompleteButton onContextMenu={(e) => onChangeFinish(e, form.id)} />
                    : <NoCompleteButton onContextMenu={(e) => onChangeFinish(e, form.id)} />,
                form.published ? <CompleteButton onContextMenu={(e) => onChangePublish(e, form.id)} />
                    : <NoCompleteButton onContextMenu={(e) => onChangePublish(e, form.id)} />,
                <>
                    <EditButton onClick={() => onEditForm(form.id)} />
                    {/* <DeleteButton onClick={()=>onDeleteForm(form.id)} /> */}
                </>
            ])
            i++
        })
        return data;
    }

    useEffect(() => {

        // Загрузка данных с сервера
        getQuestionairAllAuth().then((forms) => {
            setData(formsConvertData(forms))
        })
        // eslint-disable-next-line 
    }, [])


    return (
        <>

            <Header logout={logout} />
            <div className='container' ref={page}>
                <div key={'id'} className="admin__content__zone">
                    <div className='redactor__page__title'>
                        <h2 className=" ">Список форм</h2>
                    </div>
                    <AdminTable columnMap={columnMap} headers={headers} data={data} />
                    <div className='buttons__under__forms'>
                        <AdminButton
                            onClick={() => navigate("/changePassword")}
                            text={<><div>ИЗМЕНИТЬ ПАРОЛЬ</div></>}
                        />
                        <AdminButton
                            onClick={() => navigate("/connectionForm")}
                            text={<><div>Подключить типовую форму</div></>}
                        />
                        {userStore.isAdmin === true &&
                            < AdminButton
                                onClick={onCreateForm}
                                text={<><div>СОЗДАТЬ ФОРМУ</div><div>&#62;&#62;</div></>}
                            />}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

});

export default AdminPanelForms