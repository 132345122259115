import React, { useState } from 'react';
import "../adaptiveTable/adaptiveTable.scss";
import Button from '../../buttons/Button';
import "./textOrNon.scss";

const TextOrNon = ({
    title,
    data,
    dataHeader,
    handleInputChange }) => {

    const [answers, setAnswers] = useState({});

    const onSubmitQ = (e) => {
        e.preventDefault();
        const allQuestionsAnswered = data.every((vQuestion) => {
            const answer = answers[vQuestion.id];
            // Проверяем, что либо текст не пустой, либо чекбокс отмечен
            return answer && (answer.text || answer.checked);
        });


        if (!allQuestionsAnswered) {
            alert("Выберите один ответ по каждой строке.");
            return;
        }

        // Если все вопросы отвечены, формируем массив ответов
        const answersArray = Object.entries(answers).map(([questionId, answer]) => ({
            name: answer.text || "!", // Устанавливаем "Пусто", если текстовое поле пустое
            question: questionId,
            // checked: answer.checked
        }));

        handleInputChange(answersArray);
    };


    const handleCheckboxChange = (vQuestionId, checked) => {
        setAnswers(prev => ({
            ...prev,
            [vQuestionId]: {
                // Если чекбокс не отмечен, устанавливаем текст "Пусто"
                text: !checked ? "" : prev[vQuestionId]?.text,
                checked: checked
            }
        }));
    };

    const handleTextChange = (vQuestionId, text) => {
        setAnswers(prev => ({
            ...prev,
            [vQuestionId]: {
                text: text || "",
                checked: prev[vQuestionId]?.checked ?? false
            }
        }));
    };


    const checkTextArea = (e) => {
        const inputs = document.querySelectorAll('.form__check');
        const textArea = document.querySelectorAll('#checkboxFreeAnswer');

        if (e[0] === '\n') {
            alert("Ответ не может начинатся с переноса строки")
            textArea.forEach((item) => {
                item.value = "";
            })
        }

        if (e == " " || e == "  " || e == "   " || e == "    " || e == "     " || e == "     " || e == "     " || e == "     " || e == "     ") {
            alert("Ответ не может состоять исключительно из пробельных символов")
            textArea.forEach((item) => {
                item.value = "";
            })
        }

        if (e.length > 100) {
            textArea.value = textArea.value.substring(0, 96) + '...';
            alert("Длинна ответа не может быть больше 100 символов")
        }

        if (e.length > 0) {
            inputs.forEach(input => {
                input.checked = false
            })
        }
    }


    return (
        <>
            <form id={'point' + data[0].id}>
                <h2 className="form__question">{title}</h2>
                <div className="adaptiveTable">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                {dataHeader.map((item, i) => (
                                    <th key={i}>{item.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((vQuestion, vIndex) => (
                                <tr key={vIndex}>
                                    <td className='adaptiveTable__vQuestion' >{vQuestion.name}</td>
                                    {dataHeader.map((_, i) => {
                                        const isCheckbox = i !== 0;
                                        const isDisabled = !!answers[vQuestion.id]?.text && answers[vQuestion.id]?.text !== "Пусто";
                                        return (
                                            <td data-label={_.name}
                                                style={{ cursor: "pointer" }}
                                                key={i}
                                                onClick={() => {
                                                    if (isCheckbox && !isDisabled) {
                                                        // Тоглим состояние чекбокса при клике на td
                                                        handleCheckboxChange(vQuestion.id, !(answers[vQuestion.id]?.checked || false));
                                                    }
                                                }}
                                            >
                                                {i === 0 ? (
                                                    <textarea
                                                        disabled={answers[vQuestion.id]?.checked}
                                                        style={{ width: "250px" }}
                                                        id="checkboxFreeAnswer"
                                                        className='adaptiveTable__check textAnswer'
                                                        type="text"
                                                        value={answers[vQuestion.id]?.text || ""}
                                                        onChange={(e) => {
                                                            checkTextArea(e.target.value)
                                                            e.stopPropagation(); // Предотвращаем всплытие события
                                                            handleTextChange(vQuestion.id, e.target.value);
                                                        }}
                                                    />
                                                ) : (
                                                    <input
                                                        disabled={isDisabled}
                                                        className='adaptiveTable__check'
                                                        type="checkbox"
                                                        checked={answers[vQuestion.id]?.checked || false}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Предотвращаем всплытие события
                                                        }}
                                                        onChange={(e) => {
                                                            handleCheckboxChange(vQuestion.id, e.target.checked);
                                                        }}
                                                    />
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Button
                    onClick={onSubmitQ}
                    addClass="buttonForward"
                    title="Вперед"
                />
            </form>
        </>
    );
};

export default TextOrNon;