import './styles.scss'
import { useEffect, useState } from 'react'
import AltpointInput from '../AltPointInts/AltpointInput';


const TextboxHeader = ({
    header,
    isEditForm,
    nonDel,
    ...props
}) => {
    const [isDelete, setDelete] = useState(false)
    const id = (header) ? header.id : performance.now();
    useEffect(() => {

    }, [isEditForm])

    return (
        <>
            {isDelete ? <></> :
                <div className=' q__field__margin'>
                    <div className='btn__deletepoint__container'>
                        {
                            isEditForm && !nonDel ?
                                <button className='btn__deletepoint' onClick={(e) => {
                                    setDelete(true);
                                }}>x</button>
                                : ''
                        }

                        {
                            isEditForm ? <textarea width='100%' name={"header_" + id} defaultValue={(header) ? header.name : ''} />
                                : (header) ? header.name : ''
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default TextboxHeader