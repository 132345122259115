import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../..';
import { useNavigate } from 'react-router-dom';
import { getConnectionFormAuth, getConfirmConnection, getQuestionairAllAuth, check } from '../../http/controllers';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { userStore } from '../../store/user';
import { headerStore } from '../../store/headerStore';


const ConnectionForm = observer(() => {

    let user = useContext(UserContext);
    let navigate = useNavigate();
    const [questionnaire, setQuestionnaire] = useState(null);
    const [disabledButtons, setDisabledButtons] = useState({});
    const [data, setData] = useState(0);

    useEffect(() => {
        getConnectionFormAuth()
            .then(data => {
                setQuestionnaire(data);
                const initialDisabledState = data.reduce((state, question) => {
                    state[question.id] = false;
                    return state;
                }, {});
                setDisabledButtons(initialDisabledState);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }, []);


    const logout = () => {
        user.logout();
        navigate('/');
    };

    const sortById = (array) => {
        return array.sort((a, b) => a.id - b.id);
    };

    const handleButtonClick = async (questionId) => {

        await getConfirmConnection(questionId)
            .then((data) => {
                setData(data)
                setDisabledButtons(prevState => ({
                    ...prevState,
                    [questionId]: true
                }));

                userStore.setParentID(data.parent)
            })
            .catch(error => {
                console.error('Error confirming connection:', error);
            });
    };

    const sortedArray = questionnaire && sortById([...questionnaire]);

    console.log("GET:/list_forms", questionnaire);
    console.log('data:', data);

    return (
        <>
            <Header logout={logout} />
            <section className='startPage'>
                <h2 className='startPage__title'>Список форм</h2>
                <div className="startPage__wrapp">
                    {sortedArray && sortedArray.map((question) => {
                        const isDisabled = disabledButtons[question.id];
                        return (
                            <div key={question.id} className="startPage__item">
                                <div className="startPage__hr"></div>
                                <div className="startPage__innerItem">
                                    <p>{question.short_dscrpt}</p>
                                    <h3 className="startPage__name">{question.name}</h3>
                                    <div className="btns__forms">
                                        <button
                                            style={{ backgroundColor: isDisabled ? "#78c980" : "" }}
                                            disabled={isDisabled}
                                            title={isDisabled ? "Форма подключена" : "Подключить форму"}
                                            onClick={() => handleButtonClick(question.id)}
                                            className="startPage__button">
                                            {isDisabled ? "Подключено" : "Подключить форму"}
                                        </button>

                                        {isDisabled && (data.parent && data.questionnaire) ? <button
                                            onClick={() => {
                                                navigate('/redactorPoint', { state: { formId: data.questionnaire, formName: question.name, parent: data.parent } })
                                                // navigate('/redactorPoint', { state: { formId: data.questionnaire, formName: question.name, parent: data.parent } })
                                            }}
                                            className="startPage__button">Отредактировать вопрос</button> : ""}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
            <Footer />
        </>
    );
});

export default ConnectionForm;
