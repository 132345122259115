import AdminButton from "../../../../../components/AdminButton/AdminButton"


const AltpointInput = ({point, altId})=> {


    return (
        <>
            <div> {(point) ? <><b>{point.number}.</b> {point.name}</> : '<span style="color:red" >Ошибка: ссылка на несуществующий вопрос</span>'}  </div>
        </>
    )
}


export default AltpointInput