const goToNextQuestion = async (currentQuestion, formData, setCurrentQuestionId, endForm, postData, data) => {

    if (currentQuestion) {
        if (currentQuestion.alternatives.length !== 0) {
            let f = true;
            // Флаг для остановки внешнего цикла
            let shouldBreak = false;
            for (let j in currentQuestion.alternatives) {
                if (f) {
                    switch (currentQuestion.type_point) {
                        case 'mark': {

                            // let max = 0;

                            // for (let i in formData) {
                            //     // eslint-disable-next-line
                            //     if (formData[i].pointId == currentQuestion.id) {
                            //         max = (max < +formData[i].name) ? formData[i].name : max
                            //     }
                            // }

                            // formData.forEach((item, i) => {
                            //     if (item.pointId === currentQuestion.id) {
                            //         console.log("max:", max);
                            //         if (max >= currentQuestion.alternatives[0].condition_min && max <= currentQuestion.alternatives[0].condition_max) {
                            //             setCurrentQuestionId(currentQuestion.alternatives[j].target)
                            //         } else {
                            //             setCurrentQuestionId(currentQuestion.next);
                            //         }
                            //     }
                            // })



                            ////////////  Проверка на департамент ///////////////
                            let min = 100;

                            for (let i in formData) {
                                // eslint-disable-next-line
                                if (formData[i].pointId == currentQuestion.id) {
                                    min = (min > +formData[i].name) ? formData[i].name : min
                                }
                            }

                            formData.forEach((item, i) => {
                                if (item.pointId === currentQuestion.id) {
                                    if (min < currentQuestion.alternatives[0].condition_min && min < currentQuestion.alternatives[0].condition_max) {
                                        setCurrentQuestionId(currentQuestion.next);
                                    } else {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target)
                                    }
                                }
                            })
                            break;
                        }


                        case 'radio': {
                            for (let i = 0; i < formData.length; i++) {
                                let item = formData[i];
                                for (let j = 0; j < currentQuestion.alternatives.length; j++) {
                                    // Если альтернативный путь есть и ответ совпал с особым вопросом то идем по альтернативному пути.
                                    // eslint-disable-next-line
                                    if (currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        // Если департамент существует
                                    } else if (currentQuestion.alternatives[j].department && currentQuestion.alternatives[j].department == data.department) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        break;
                                        // Если альтернативный путь отсутсвует и ответ совпал с особым вопросом то заканчиваем опрос.
                                        // eslint-disable-next-line
                                    } else if (!currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        endForm();
                                        shouldBreak = true;
                                        postData(formData);
                                        break;
                                    }   // Если ответ не совпал с особым вопросом то идем дальше.
                                    else if (currentQuestion.alternatives[j].mrk_qstn !== item.question) {
                                        setCurrentQuestionId(currentQuestion.next);
                                    }
                                }
                                if (shouldBreak) {
                                    return;
                                }
                            }
                            break;
                        }

                        case "ch_box&text": {
                            for (let i = 0; i < formData.length; i++) {
                                let item = formData[i];
                                for (let j = 0; j < currentQuestion.alternatives.length; j++) {
                                    // Если альтернативный путь есть и ответ совпал с особым вопросом то идем по альтернативному пути.
                                    // eslint-disable-next-line
                                    if (currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        break;
                                        // Если департамент существует
                                        // eslint-disable-next-line
                                    } else if (currentQuestion.alternatives[j].department && currentQuestion.alternatives[j].department == data.department) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        break;
                                        // Если альтернативный путь отсутсвует и ответ совпал с особым вопросом то заканчиваем опрос.
                                        // eslint-disable-next-line
                                    } else if (!currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        endForm();
                                        shouldBreak = true;
                                        postData(formData);
                                        break;
                                    }  // Если ответ не совпал с особым вопросом то идем дальше.
                                    else if (currentQuestion.alternatives[j].mrk_qstn !== item.question) {
                                        setCurrentQuestionId(currentQuestion.next);
                                    }
                                }
                                if (shouldBreak) {
                                    return;
                                }
                            }
                            break;
                        }


                        case "radio&text": {
                            for (let i = 0; i < formData.length; i++) {
                                let item = formData[i];
                                for (let j = 0; j < currentQuestion.alternatives.length; j++) {
                                    // Если альтернативный путь есть и ответ совпал с особым вопросом то идем по альтернативному пути.
                                    // eslint-disable-next-line
                                    if (currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);

                                        break;
                                        // Если департамент существует
                                        // eslint-disable-next-line
                                    } else if (currentQuestion.alternatives[j].department && currentQuestion.alternatives[j].department == data.department) {

                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        break;
                                        // Если альтернативный путь отсутсвует и ответ совпал с особым вопросом то заканчиваем опрос.
                                        // eslint-disable-next-line
                                    } else if (!currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        endForm();
                                        shouldBreak = true;
                                        postData(formData);
                                        break;
                                    } // Если ответ не совпал с особым вопросом то идем дальше.
                                    else if (currentQuestion.alternatives[j].mrk_qstn !== item.question) {
                                        setCurrentQuestionId(currentQuestion.next);
                                    }
                                }
                                if (shouldBreak) {
                                    return;
                                }
                            }
                            break;
                        }
                        case "check_box": {
                            for (let i = 0; i < formData.length; i++) {
                                let item = formData[i];
                                for (let j = 0; j < currentQuestion.alternatives.length; j++) {
                                    // Если альтернативный путь есть и ответ совпал с особым вопросом то идем по альтернативному пути.
                                    // eslint-disable-next-line
                                    if (currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        break;
                                        // Если департамент существует
                                        // eslint-disable-next-line
                                    } else if (currentQuestion.alternatives[j].department && currentQuestion.alternatives[j].department == data.department) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        break;
                                        // Если альтернативный путь отсутсвует и ответ совпал с особым вопросом то заканчиваем опрос.
                                        // eslint-disable-next-line
                                    } else if (!currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        endForm();
                                        shouldBreak = true;
                                        postData(formData);
                                        break;
                                    } // Если ответ не совпал с особым вопросом то идем дальше.
                                    else if (currentQuestion.alternatives[j].mrk_qstn !== item.question) {
                                        setCurrentQuestionId(currentQuestion.next);
                                    }
                                }
                                if (shouldBreak) {
                                    return;
                                }
                            }
                            break;
                        }
                        case "text_or_non": {
                            for (let i = 0; i < formData.length; i++) {
                                let item = formData[i];
                                for (let j = 0; j < currentQuestion.alternatives.length; j++) {
                                    // Если альтернативный путь есть и ответ совпал с особым вопросом то идем по альтернативному пути.
                                    // eslint-disable-next-line
                                    if (currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        break;
                                        // Если департамент существует
                                        // eslint-disable-next-line
                                    } else if (currentQuestion.alternatives[j].department && currentQuestion.alternatives[j].department == data.department) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        break;
                                        // Если альтернативный путь отсутсвует и ответ совпал с особым вопросом то заканчиваем опрос.
                                        // eslint-disable-next-line
                                    } else if (!currentQuestion.alternatives[j].target && currentQuestion.alternatives[j].mrk_qstn == item.question) {
                                        setCurrentQuestionId(currentQuestion.alternatives[j].target);
                                        endForm();
                                        shouldBreak = true;
                                        postData(formData);
                                        break;
                                    } // Если ответ не совпал с особым вопросом то идем дальше.
                                    else if (currentQuestion.alternatives[j].mrk_qstn !== item.question) {
                                        setCurrentQuestionId(currentQuestion.next);
                                    }
                                }
                                if (shouldBreak) {
                                    return;
                                }
                            }
                            break;
                        }
                        default: {

                        }
                    }
                } else break
                // setCurrentQuestionId(currentQuestion.alternatives.target)
            }
        }
        if (currentQuestion.next == null) {
            endForm();
            postData(formData);
        }

        if (currentQuestion.alternatives.length === 0) {
            setCurrentQuestionId(currentQuestion.next);
        }
    }
};

export default goToNextQuestion;