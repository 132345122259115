import "./page404.scss";
import Button from "../../components/buttons/Button";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const Page404 = () => {

  const navigate = useNavigate();

  return (
    <section className='page404'>
      <Header />
      <div className="page404__content">
        <h3 className="page404__title">404</h3>
        <h4>Страница не найдена</h4>
        <p>Страница, на которую вы пытаетесь <br />
          попасть, не существует или была удалена. <br />
          перейдите на <a href="/">Главную страницу</a> </p>
      </div>
      <Button onClick={() => navigate('/')} addClass={"page404__buttonBack"} />
      <Footer />
    </section>
  )
}

export default Page404