import React from 'react'
import RadioAnswer from '../radionAnswer/RadioAnswer';
import AdaptiveTable from '../adaptiveTable/AdaptiveTable';
import CheckboxAnswer from '../checkboxAnswer/CheckboxAnswer';
import FreeAnswer from '../freeAnswer/FreeAnswer';
import TextOrNon from '../textOrNon/TextOrNon';
import NumberAnswer from '../numberAnswer/NumberAnswer';
import CheckboxFreeAnswer from '../checkboxAndText/CheckboxAndText';
import RadioAndText from "../radioAndText/RadioAndText";
// 
const FormView = ({ questionnaire, currentQuestion, data, selectedRadioValue, handleRadioChange, handleInputChange, setFormData, goToNextQuestion }) => {
    return (
        <section className="form">
            <h2 className="form__title">{questionnaire && questionnaire.name}</h2>
            {currentQuestion && (
                <div key={currentQuestion.id} className="formWrapp">
                    {/* Отрисовка в зависимости от типа вопроса */}
                    <p className="form__count">{`${currentQuestion.number} / ${data.points.length}`}</p>
                    {(() => {

                        switch (currentQuestion.type_point) {
                            case "radio":
                                return (
                                    <RadioAnswer
                                        title={currentQuestion.name}
                                        data={currentQuestion.questions}
                                        selectedValue={selectedRadioValue}
                                        handleRadioChange={handleRadioChange}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                        currentQuestion={currentQuestion}
                                        goToNextQuestion={goToNextQuestion}
                                    />
                                );
                            case "raiting":
                                return (
                                    <AdaptiveTable
                                        title={currentQuestion.name}
                                        data={currentQuestion.questions}
                                        dataHeader={currentQuestion.headers}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                        currentQuestion={currentQuestion}
                                    />
                                );
                            case "check_box":
                                return (
                                    <CheckboxAnswer
                                        title={currentQuestion.name}
                                        data={currentQuestion.questions}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                    />
                                );
                            case "text":
                                return (
                                    <FreeAnswer
                                        title={currentQuestion.name}
                                        currentQuestion={currentQuestion}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                    />
                                );
                            case "text_or_non":
                                return (
                                    <TextOrNon
                                        title={currentQuestion.name}
                                        data={currentQuestion.questions}
                                        dataHeader={currentQuestion.headers}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                    />
                                );
                            case "mark":
                                return (
                                    <NumberAnswer
                                        title={currentQuestion.name}
                                        data={currentQuestion.questions}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                        currentQuestion={currentQuestion}
                                    />
                                );
                            case "ch_box&text":
                                return (
                                    <CheckboxFreeAnswer
                                        title={currentQuestion.name}
                                        data={currentQuestion.questions}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                        handleRadioChange={handleRadioChange}
                                        currentQuestion={currentQuestion}
                                    />
                                );
                            case "radio&text":
                                return (
                                    <RadioAndText
                                        title={currentQuestion.name}
                                        data={currentQuestion.questions}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                        handleRadioChange={handleRadioChange}
                                        currentQuestion={currentQuestion}
                                    />
                                );
                            case "department":
                                return (
                                    <FreeAnswer
                                        title={currentQuestion.name}
                                        currentQuestion={currentQuestion}
                                        handleInputChange={(e) => handleInputChange(e, currentQuestion, setFormData)}
                                    />
                                );
                            default:
                                return <p>Неизвестный тип вопроса: {currentQuestion.type_point}</p>;
                        }
                    })()}
                </div>
            )}
        </section>
    )
}

export default FormView