
//_______________ОТОБРАЖЕНИЕ ВСЕХ КОЛОНОК_______________//

// import './styles.scss'

// import Button from "../../components/buttons/Button"
// import Footer from "../../components/footer/Footer"
// import Header from "../../components/header/Header"
// import { authorization } from '../../http/controllers'
// import { useContext, useState } from 'react'
// import { UserContext } from '../..'
// import { useNavigate } from "react-router-dom";



// const AdminTable = ({
//     columnMap,
//     data,
//     headers
// }) => {

//     return (
//         <>
//             <div className="admin__table">
//                 <div className='admin__table__tr admin__table__hr' style={{ gridTemplateColumns: columnMap }}>
//                     {headers.map(head => <div className='admin__table__th'>{head}</div>)}
//                 </div>

//                 {data.map(form => <div className='admin__table__tr' style={{ gridTemplateColumns: columnMap }}>{
//                     form.map(col => <div className='admin__table__td'>{col}</div>)
//                 }</div>)}
//             </div>
//         </>
//     )


// }

// export default AdminTable


import './styles.scss'

const AdminTable = ({
    columnMap,
    data,
    headers
}) => {

    // Убираем последний заголовок
    const updatedHeaders = headers.slice(0, -1);
    const updatedData = data.map(row => row.slice(0, -1));
    const updatedColumnMap = columnMap.split(' ').slice(0, -1).join(' ');

    return (
        <>
            <div className="admin__table">
                <div className='admin__table__tr admin__table__hr' style={{ gridTemplateColumns: updatedColumnMap }}>
                    {updatedHeaders.map((head, index) => <div key={index} className='admin__table__th'>{head}</div>)}
                </div>

                {updatedData.map((row, rowIndex) => (
                    <div key={rowIndex} className='admin__table__tr' style={{ gridTemplateColumns: updatedColumnMap }}>
                        {row.map((col, colIndex) => <div key={colIndex} className='admin__table__td'>{col}</div>)}
                    </div>
                ))}
            </div>
        </>
    );
}

export default AdminTable