import Button from "../../buttons/Button";
import "./freeAnswer.scss";

const FreeAnswer = ({ title, currentQuestion, handleInputChange }) => {
    let idQuestion;
    try {
        idQuestion = currentQuestion.questions[0] ? currentQuestion.questions[0].id : -1
    } catch (e) {
        idQuestion = -1
        alert("Отсутсвуют question")
    }

    const onSubmitQ = (e) => {
        const textArea = document.getElementById(`question_${idQuestion}`)
        if (textArea.value.length === 0) {
            alert('Выберите хотя бы один ответ.');
            return;
        }
        let answers = []


        let elHtml = document.getElementById(`question_${idQuestion}`)
        if (elHtml)
            answers.push({
                name: elHtml.value,
                question: idQuestion
            })

        handleInputChange(answers)
    }


    const checkTextArea = (e) => {
        const inputs = document.querySelectorAll('.form__check');
        const textArea = document.querySelector(`#question_${idQuestion}`);

        if (e[0] === '\n') {
            alert("Ответ не может начинатся с переноса строки")
            textArea.value = "";
        }


        if (e == " " || e == "  " || e == "  " || e == "    " || e == "     " || e == "     " || e == "     " || e == "     " || e == "     ") {
            alert("Ответ не может состоять исключительно из пробельных символов")
            textArea.value = ""
        }

        if (e.length > 100) {
            textArea.value = textArea.value.substring(0, 96) + '...';
            alert("Длинна ответа не может быть больше 100 символов")

        }

        if (e.length > 0) {
            inputs.forEach(input => {
                input.checked = false
            })
        }
    }

    return (
        <>
            <h2 className="form__question">{title}</h2>
            <div className="form__freeAnswerWrapp">
                <textarea
                    onChange={(e) => checkTextArea(e.target.value)}
                    id={`question_${idQuestion}`}
                    className="form__freeAnswer"></textarea>
            </div>
            <Button
                onClick={onSubmitQ}
                addClass="buttonForward"
                title="Вперед"
            />
        </>
    )
}

export default FreeAnswer;