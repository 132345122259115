import './styles.scss'

import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { createNewPoint, editPoint, getPointById, getQuestionairAllAuth, getQuestionairById, getQuestionairByIdAuth, getTypesQuestions } from '../../http/controllers';
import { UserContext } from '../..';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import AdminButton from '../../components/AdminButton/AdminButton';
import QuestionsFieldsInputs from './components/QuestionsFieldsInputs';
import QuestionsFieldsMark from './components/QuestionsFieldsMark';
import QuestionsFieldsTable from './components/QuestionsFieldsTable';
import QuestionsFieldsInputsAndText from './components/QuestionsFieldsInputsAndText';
import QuestionsFieldsTextOrNon from './components/QuestionsFieldsTextOrNon';
import NumberOfPoint from './components/NumberOfPoint';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { userStore } from '../../store/user';

const RedactorPoint = () => {

    let user = useContext(UserContext)
    const { pointId } = useParams()
    const navigate = useNavigate();
    const [isLogout, setIsLogout] = useState(false)
    const [typePoint, setTypePoint] = useState('')
    const location = useLocation();
    const { formId, formName, parent } = location.state
    const [currentPoint, setCurrentPoint] = useState()
    const [allPoints, setAllPoints] = useState([])
    const [typesPoint, setTypesPoint] = useState()
    const [isEditForm, setIsEditForm] = useState(pointId ? false : true)
    const [errMessage, setErrMessage] = useState(<></>)


    const logout = () => {
        user.logout()
        setIsLogout(!isLogout)
        navigate('/')
    }

    let formHtml = useRef()
    useEffect(() => {

        getTypesQuestions().then(data => {
            setTypesPoint(data)
        }).catch(e => console.log('ERROR: ' + e.message + ' (не удалось получить типы поинтов)')).then(() => {
            if (pointId) {
                getPointById(pointId).then((data) => {
                    setCurrentPoint(data)
                    setTypePoint(data.type_point)
                }).catch((e) => console.log('ERROR: ', e.message)).then(() => {

                })
            } else {

                setCurrentPoint(null)
                setIsEditForm(true)
            }
            //Условие парент
            if (parent && !formId) {
                getQuestionairAllAuth().then((test, i) => {
                    getQuestionairByIdAuth(test[test.length - 1].id).then((data) => {
                        setAllPoints(data.points)
                    }).catch((e) => console.log('ERROR: ', e.message))
                }).catch((e) => console.log('ERROR: ', e.message))
            }
            if (formId && !parent)
                getQuestionairByIdAuth(formId).then((data) => {
                    setAllPoints(data.points)
                }).catch((e) => console.log('ERROR: ', e.message))
        })
    }, [pointId, isEditForm]) //typePoint

    useEffect(() => {
        if (typesPoint) setTypePoint(typesPoint[Object.keys(typesPoint)[0]])
    }, [pointId])

    const changeType = (e) => {
        setTypePoint(e.target.value)
    }

    const onSavePoint = async () => {
        let flag = true
        if (pointId)
            flag = window.confirm('Вы уверены, что хотите применить изменения? Все альтернативные пути формы опроса будут потеряны')
        if (flag) {
            let formData = new FormData(formHtml.current)

            let isAltrnatives = false; // Только для таблиц с оценками
            let questions = []
            let headers = []
            let alternatives = []
            let newData = {} //итоговый объект

            if (pointId) newData['id'] = pointId // устанавливаем айди поинта для редактирования если он есть

            formData.forEach((val, key) => {
                // для уникальности полей в форме они записываются в формате типПовторяемогоПоля_сгенерированныйИлиУжеСуществующийАйди
                let t = key.split('_')[0] // t - тип поля, key.split('_')[1] - айди объекта. Временный или постоянный
                if (t == 'question') // обработка вопросов поинта
                    questions.push({
                        'name': val,
                        'point': currentPoint ? currentPoint.id : null
                    })
                else if (t == 'header') // обработка заголовков таблицы вопроса (если они есть)
                    headers.push({
                        'name': val
                    })
                else if (t == 'alternative') { } // обработка альтернатив больше не нужна
                else if (t == 'number') newData['number'] = formData[val] // обработка альтернатив больше не нужна
                else if (key == 'condition_max' || key == 'condition_min') { // поля, которые появляются при обработке таблиц с оценками
                    let condition = (key == 'condition_min') ? 'condition_min' : 'condition_max'
                    let alt = {}
                    alt[condition] = val
                    alternatives.push(alt)
                }
                else {
                    newData[key] = val
                }
            })

            newData.questions = questions.map((q, i) => { // добавляется список вопросов (проиходит добавление нумерации) либо пустой массив
                q.number = i + 1;
                return q
            });


            // далее финальная обработка данных каждого типа
            if (typePoint == 'mark') {
                newData.alternatives = (isAltrnatives) ? [Object.assign(...alternatives)] : [] //объеденение всех объектов в один, для таблицы оценок, поскольку каждое поле добавлялось отдельным объектом
                newData.headers = [{ name: 'empty', num_col: 0 }] // статично для таблицы с оценками
            }
            else if (typePoint == 'text') {
                newData.questions.push({
                    'name': formData.get('name'),
                    'point': currentPoint ? currentPoint.id : null,
                    'number': 1
                })
            }
            else newData.alternatives = alternatives // альтернатива для всех остальных типов вопроса
            newData.headers = headers.map((h, i) => { // добавляется список заголовков (проиходит добавление нумерации) либо пустой массив
                h.num_col = i + 1;
                return h
            });



            newData['num_column'] = headers.length
            newData['number'] = formData.get('number')
            newData['questionnaire'] = formId;


            let mess = await validationPoint(newData);

            // if (parent) {
            //     delete newData['number'];
            // }
            if (!mess) {
                if (pointId) editPoint(newData, pointId).then((data) => {
                    onCreateFormSuccess();
                    navigate(0, { state: { formId: formId, formName: formName } })
                })
                else createNewPoint(newData, userStore.parentID).then((data) => {
                    onCreateFormSuccess();
                    if (parent) {
                        navigate('/')
                    } else {
                        navigate('/redactorPoint/' + data.id, { state: { formId: formId, formName: formName } })
                    }

                })
            } else setErrMessage(<div className='' style={{ color: 'red', margin: '0 5rem' }}>{mess}</div>)


        }
    }

    const validationPoint = (point) => {
        if (!point.name) return 'Введите текст вопроса'
        if (!point.type_point in typesPoint) return 'Некорректный тип вопроса'
        if (!(point.number <= (allPoints.length + 1) && point.number > 0)) return 'Некорректный номер вопроса'

        if (point.type_point == 'text') return ''

        if (!point.questions || point.questions.length == 0) return 'Невозможно создать вопрос без вариантов ответа'

        for (let i in point.questions)
            if (!point.questions[i].name || point.questions[i].name == '') return 'Варианты ответов не могут быть пустыми'

        if (point.type_point == 'check_box' || point.type_point == 'radio' || point.type_point == 'ch_box&text' || point.type_point == 'radio&text' || point.type_point == 'mark') return ''

        if (!point.headers || point.headers.length == 0) return 'Необходимо указать заголовки для таблицы'

        for (let i in point.headers)
            if (!point.headers[i].name || point.headers[i].name == '') return 'Заголовки не могут быть пустыми'

        if (point.type_point == 'raiting') return ''

        if (point.headers.length != 2 && point.type_point == 'text_or_non') return 'Внимание! Фиксированое количество заголовков 2'

        return ''
    }


    const onCreateFormSuccess = () => {
        Store.addNotification({
            title: "Успех!",
            message: "Вопрос сохранен",
            type: "success",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }


    return (
        <>

            <Header logout={logout} />
            <div className='breadcrumbs'>
                <span onClick={() => navigate('/admin')}>Формы</span> /
                <span onClick={() => navigate('/redactorform', { state: { formId: formId } })}>{formName || 'Форма №' + formId}</span> /
                <span> Вопрос № {(currentPoint) ? currentPoint.number : ''} </span>
            </div>
            <div className='container' >

                <div key={'id'} className="admin__content__zone">
                    <div className="redactor__page__title">
                        <h2 className=" "> {(pointId) ? 'Редактирование вопроса' : 'Новый вопрос'}</h2>
                        <div>
                            Редактирование
                            <input type='checkbox' id="switch" className='checkbox' checked={isEditForm ? 'checked' : ''} onChange={(e) => e.target.checked ? setIsEditForm(true) : setIsEditForm(false)} />
                            <label for="switch" className="toggle"></label>
                        </div>
                    </div>
                    <form ref={formHtml}>
                        <div className='formField'>
                            <div className="formField__oneRow">
                                <div className="fieldName">Тип вопроса</div>
                                {/* Выбор типа поинта. В зависимости от выбранного селекта отображается контент ниже */}
                                <div>
                                    {isEditForm ?
                                        <select name='type_point' onChange={changeType}>
                                            {
                                                typesPoint ? Object.keys(typesPoint).map((key) =>
                                                    <option value={key} selected={(pointId && typePoint == key) ? 'selected' : ''} >{typesPoint[key]}</option>
                                                ) : ''
                                            }
                                        </select>
                                        : currentPoint ? currentPoint.type_point_description : 'Выбранный вопрос не существует'
                                    }

                                </div>
                            </div>
                            <div className='fieldName'>
                                <div>Текст вопроса :</div>
                                {allPoints ? <NumberOfPoint currentNumber={currentPoint ? currentPoint.number : 0}
                                    count={allPoints ? allPoints.length : 0}
                                    pointId={pointId}
                                    isEditForm={isEditForm}
                                /> : ''}
                            </div>

                            {/* Обязательное поле для всех видов поинтов */}
                            <div className="formField__oneField">
                                {
                                    isEditForm ? <textarea name='name' type='text' defaultValue={(currentPoint) ? currentPoint.name : ''} />
                                        : currentPoint ? currentPoint.name : ''
                                }

                            </div>
                            {/* Отображение полей вопросов в зависимости от типа поинта 
                                структура:  point - текущий поинт для установки связи
                                            points - Список всех поинтов данной формы  для установки переходов
                            */}
                            {(typePoint == 'radio') ? (allPoints) ? <QuestionsFieldsInputs typePoint={typePoint} isEditForm={isEditForm} point={currentPoint} points={allPoints || []} /> : '' : ''}
                            {(typePoint == 'check_box') ? (allPoints) ? <QuestionsFieldsInputs isEditForm={isEditForm} point={currentPoint} points={allPoints || []} /> : '' : ''}
                            {(typePoint == 'text_or_non') ? (allPoints) ? <QuestionsFieldsTextOrNon isEditForm={isEditForm} point={currentPoint} points={allPoints || []} /> : '' : ''}
                            {(typePoint == 'ch_box&text') ? (allPoints) ? <QuestionsFieldsInputsAndText isEditForm={isEditForm} point={currentPoint} points={allPoints || []} /> : '' : ''}
                            {(typePoint == 'radio&text') ? (allPoints) ? <QuestionsFieldsInputsAndText isEditForm={isEditForm} point={currentPoint} points={allPoints || []} /> : '' : ''}
                            {(typePoint == 'mark') ? (allPoints) ? <QuestionsFieldsMark isEditForm={isEditForm} point={currentPoint} points={allPoints || []} /> : '' : ''}
                            {(typePoint == 'raiting') ? (allPoints) ? <QuestionsFieldsTable isEditForm={isEditForm} point={currentPoint} points={allPoints || []} /> : '' : ''}

                        </div>
                    </form>
                    {
                        isEditForm ? errMessage : ''
                    }
                    <div className='buttons__under__forms'>
                        {!isEditForm ? <AdminButton classes='btn_new_q_on_q'
                            onClick={(e) => {
                                navigate('/redactorPoint', { state: { formId: formId, formName: formName } })
                            }}
                            text={<><div>СОЗДАТЬ НОВЫЙ ВОПРОС</div></>}
                        /> : ''}
                        {isEditForm ?
                            <AdminButton
                                onClick={() => {
                                    onSavePoint()
                                    if (parent) {
                                        navigate('/')
                                    }
                                }}
                                text={<><div>СОХРАНИТЬ ВОПРОС</div><div>&#62;&#62;</div></>}
                            />
                            : ''}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default RedactorPoint