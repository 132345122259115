import { useEffect, useRef, useState } from 'react'
import TextboxAndAlternatives from '../TextboxAndAlternatives/input'
import { useNavigate } from 'react-router-dom';
import './styles.scss'
import AdminButton from '../../../../components/AdminButton/AdminButton'
import ModalAltRPoint from '../ModalAltRPoint'
import { createNewAltPoint, editPoint } from '../../../../http/controllers'
import { userStore } from '../../../../store/user';

const QuestionsFieldsInputs = ({
    point,
    points,
    typePoint,
    isEditForm,
    ...props
}) => {
    const container = useRef()
    const [fields, setFields] = useState([])
    const [isNewAlt, setIsNewAlt] = useState(false)
    const [r, setR] = useState(false)
    const navigate = useNavigate();


    useEffect(() => {
        if (point && point.questions) {
            let newArrFields = []
            point.questions.forEach((q) => {
                newArrFields.push({
                    template: <TextboxAndAlternatives isEditForm={isEditForm} alternative={point.alternatives.find((alt) => alt.mrk_qstn == q.id)} question={q} points={points} />
                })
            })
            setFields(newArrFields)
        }
        // eslint-disable-next-line
    }, [isEditForm])

    const addQuestion = () => {
        setFields([...fields, { template: <TextboxAndAlternatives isEditForm={true} points={points} /> }])
    }


    const saveAlternative = (alt) => {
        let newAlt = {
            "condition_bool": true,
            "condition_min": null,
            "condition_max": null,
            "parent": point.id,
            "mrk_qstn": null,
            "target": null
        }
        if (alt) {
            newAlt.mrk_qstn = alt.question
            newAlt.target = alt.alternative
        }
        createNewAltPoint(newAlt, userStore.parentID).then(data => {
            setR(!r)
            navigate(0, { state: { pointId: point.id, formId: point.questionnaire, formName: '' } })
        })
    }

    return (
        <>
            <div>
                <div className='questionFields__container'>
                    <div className="fieldName">Варианты ответов</div>
                    <div className="fieldName">
                        <div>Переход</div>
                        {
                            !isEditForm ?
                                <>
                                    <AdminButton classes='btn_new_q_on_q' style={{ fontSize: '.8rem', justifyContent: 'center', fontWeight: 'normal', margin: 0 }}
                                        onClick={(e) => {
                                            let conf = window.confirm('Вы уверены, что хотите удалить все альтернативные пути?')
                                            if (conf) {
                                                let newPont = point
                                                newPont.alternatives = []
                                                editPoint(newPont, newPont.id).then((data) => navigate(0, { state: { pointId: newPont.id, formId: newPont.questionnaire, formName: '' } }))
                                            }
                                        }}
                                        text={<><div>СБРОС</div></>}
                                    />
                                    <AdminButton classes='btn__add_alt'
                                        onClick={(e) => {
                                            setIsNewAlt(true)
                                        }}
                                        text={<><div>ДОБАВИТЬ АЛЬТЕРНАТИВНЫЙ ПУТЬ</div></>}
                                    />
                                </>
                                : ''
                        }


                    </div>
                </div>
                <div className='questionsContainer' ref={container}>
                    {fields.map((el) => el.template)}
                </div>
                {
                    isEditForm
                        ? <div className='btn__adQduestion' onClick={addQuestion}>
                            +
                        </div>
                        : ''
                }

            </div>


            {(isNewAlt) ? <ModalAltRPoint
                closeModal={setIsNewAlt}
                formId={point.questionnaire}
                pointId={point.id}
                questions={point.questions}
                points={points}
                alternatives={point.alternatives}
                saveAlternative={saveAlternative}
            />
                : ''}
        </>
    )
}

export default QuestionsFieldsInputs