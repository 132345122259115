import { useState, useEffect } from "react";
import Button from "../../buttons/Button";

const RadioAnswer = ({
    title,
    data,
    selectedValue,
    handleRadioChange,
    handleInputChange,
    currentQuestion,
}) => {

    const [checkedState, setCheckedState] = useState(
        data.reduce((state, item) => ({ ...state, [item.name]: false }), {})
    );

    const handleCheckboxChange = (event) => {
        const { name } = event.target;
        // Обновляем состояние так, чтобы только один ответ был выбран
        setCheckedState(prevState => {
            const newState = { ...prevState };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[name] = true;
            return newState;
        });
    };

    const handleAnswerClick = (name) => {
        // Обновляем состояние checkedState напрямую
        setCheckedState(prevState => {
            const newState = { ...prevState };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[name] = true;
            return newState;
        });
        // Вызываем handleRadioChange для обновления родительского состояния
        handleRadioChange({ target: { name: `point${currentQuestion.id}`, value: name } });
    };

    const onSubmitQ = (e) => {
        e.preventDefault();
        const selectedAnswer = Object.keys(checkedState).find(key => checkedState[key]);
        if (!selectedAnswer) {
            alert('Выберите хотя бы один ответ.');
            return;
        }

        const answers = [];
        data.forEach((item) => {
            let elHtml = document.getElementById(`question_${item.id}`)
            if (elHtml.checked) {
                answers.push({
                    name: elHtml.value,
                    question: item.id,
                })
            }
        })

        handleInputChange(answers);
    };




    useEffect(() => {
        // Сброс состояния checked для всех радио-кнопок
        setCheckedState(data.reduce((state, item) => ({ ...state, [item.name]: false }), {}));
    }, [data]);

    return (
        <>
            <form id={'point' + data[0].id}>
                <h2 className="form__question">{title}</h2>
                <div className="form__wrappAnswer">
                    {data.map((item, i) => (
                        <div
                            key={i}
                            className={`form__answer ${checkedState[item.name] ? 'selectedRadio' : ''}`}
                            onClick={() => handleAnswerClick(item.name)}
                        >
                            <div className="form__wrappContent">
                                <input
                                    id={`question_${item.id}`}
                                    type="radio"
                                    name={`point${currentQuestion.id}`}
                                    value={item.name}
                                    className="form__check"
                                    checked={checkedState[item.name]}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={`question_${item.id}`} className="form__answerDescr">
                                    {item.name}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
                <Button
                    onClick={onSubmitQ}
                    addClass="buttonForward"
                    title="Вперед"
                />
            </form>
        </>
    );
};

export default RadioAnswer;
