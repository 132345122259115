// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Header from "../header/Header";
// import Footer from "../footer/Footer";
import "./loading.scss";

const Loading = () => {
    return <>
        <section className="dualRingSpinnerWrapp">
            <div style={{ marginLeft: "45vw" }} className="dualRingSpinner"></div>
            <div className="dualRingSpinner"></div>
            <div className="dualRingSpinner"></div>
        </section>
    </>
}

export default Loading