import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import emblem from "../../img/emblem.png";
import "./header.scss";
import { observer } from "mobx-react";
import { userStore } from "../../store/user";
import { check } from "../../http/controllers";
import { headerStore } from "../../store/headerStore";

const Header = observer((data) => {
    const navigate = useNavigate();
    // const location = useLocation();

    // const isFormPage = location.pathname === '/form';

    // const dept = sessionStorage.getItem("department");

    const [role, setRole] = useState("");

    useEffect(() => {
        check().then((data) => {
            setRole(data.data.role)
        }).catch((err) => (err));
    }, [])

    return (
        <header className="header">
            <div src={emblem} className="header__emblem">
                {/*  eslint-disable-next-line */}
                <a href="https://saratov.gov.ru/gov/auth/mininform/" className="header__emblem"></a>
            </div>
            {!userStore.isAuth ? (
                // <a className="header__title" href="/"> {role ? role : "Министерство цифрового развития и связи Саратовской области"} </a>
                <a className="header__title" href="/"> {data.department ? data.department.department : "Министерство цифрового развития и связи Саратовской области"} </a>
            ) : (
                // <a className="header__title" href="/"> {data.department ? data.department.department : "Министерство цифрового развития и связи Саратовской области"} </a>
                <a className="header__title" href="/"> {role ? role : "Министерство цифрового развития и связи Саратовской области"} </a>
            )}
            {userStore.isAuth ? (
                <div className="btn_auth" onClick={(e) => {
                    sessionStorage.setItem("token", "");
                    sessionStorage.setItem("department", "");
                    userStore.isAuth = false;
                    userStore.isAdmin = false;
                    navigate('/');
                }}>Выход</div>
            ) : (
                <div className="btn_auth" onClick={() => navigate('/login')}>Вход</div>
            )}
        </header>
    );
});

export default Header;
