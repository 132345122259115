import { useRef, useState } from "react";
import AdminButton from "../../../../../components/AdminButton/AdminButton"
import { createNewAltPoint, editPoint } from "../../../../../http/controllers";
import { useNavigate } from 'react-router-dom';
import { userStore } from "../../../../../store/user";

const AltpointMark = ({ point, points, isEditForm }) => {

    const [isEditAlt, setIsEditAlt] = useState(false)
    const [message, setMessage] = useState(<></>)
    const formHTML = useRef()
    const navigate = useNavigate();

    let names = {}
    points.forEach(point => {
        let strs = point.name.split(' ')
        let name = ''
        let i = 0
        while (name.length < 50 && strs.length > i) {
            name += strs[i] + ' '
            i++
        }

        if (strs.length > i) name += '...'
        names[point.id] = name

    });

    const saveAlternate = () => {
        let data = new FormData(formHTML.current)

        if (data.get('condition_max') - data.get('condition_min') < 0) {
            setMessage(<div style={{ color: 'red' }}>Неверные границы значений</div>)
            return
        }
        if (data.get('target') == -1) {
            setMessage(<div style={{ color: 'red' }}>Выберите значение</div>)
            return
        }
        if (data.get('target') == point.id) {
            setMessage(<div style={{ color: 'red' }}>Вопрос не может ссылаться сам на себя</div>)
            return
        }

        let newAlt = {
            "condition_bool": false,
            "condition_min": data.get('condition_min'),
            "condition_max": data.get('condition_max'),
            "parent": point.id,
            "mrk_qstn": null,
            "target": data.get('target'),
        }

        createNewAltPoint(newAlt, userStore.parentID).then(data => {
            point.alternatives = [data]
            setIsEditAlt(false)
            setMessage(<></>)
        })
    }

    console.log(userStore.parentID);
    return (
        <>
            <div>
                <form ref={formHTML}>
                    {
                        !point || point.alternatives == [] || !point.alternatives ? <div>нет</div> : <>
                            <div>
                                <div className="formField__oneRow">
                                    <div className="">MAX</div>
                                    <div>
                                        {
                                            isEditAlt ? <input name='condition_max' type='number' defaultValue={(point && point.alternatives[0]) ? point.alternatives[0].condition_max : 0} />
                                                : (point && point.alternatives[0]) ? point.alternatives[0].condition_max : 0
                                        }
                                    </div>
                                </div>
                                <div className="formField__oneRow">
                                    <div className="">MIN</div>
                                    <div>
                                        {
                                            isEditAlt ? <input name='condition_min' type='number' defaultValue={(point && point.alternatives[0]) ? point.alternatives[0].condition_min : 0} />
                                                : (point && point.alternatives[0]) ? point.alternatives[0].condition_min : 0
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                isEditAlt
                                    ? <select name='target'>
                                        <option value='-1' >нет</option>
                                        {points.map((point_) => <option value={point_.id} selected={(point && point.alternatives[0] && point.alternatives[0].target == point_.id) ? 'selected' : ''}>{point_.number} {names[point_.id]}</option>)}
                                    </select >
                                    : point.alternatives[0]
                                        ? points.map((point_) => (point_.id == point.alternatives[0].target) ? <><b>{point_.number}</b>  {names[point_.id]}</> : '')
                                        : 'нет'
                            }
                        </>}
                    <div></div>
                    {message}

                    {
                        !isEditForm ?
                            <div className="buttons__under__forms" style={{ margin: 0 }}>
                                {
                                    point && point.alternatives[0] ?
                                        <AdminButton classes='btn_new_q_on_q' style={{ fontSize: '.8rem', justifyContent: 'center', fontWeight: 'normal', margin: 0 }}
                                            onClick={(e) => {
                                                let conf = window.confirm('Вы уверены, что хотите сбросить альтернативный путь? Все альтернативы формы будут утеряны')
                                                if (conf) {
                                                    let newPont = point
                                                    newPont.alternatives = []
                                                    editPoint(newPont, newPont.id).then((data) => navigate(0, { state: { pointId: newPont.id, formId: newPont.questionnaire, formName: '' } }))
                                                }
                                            }}
                                            text={<><div>СБРОС</div></>}
                                        />
                                        : <>{isEditAlt ?
                                            <div>
                                                <AdminButton
                                                    onClick={saveAlternate}
                                                    text={'СОХРАНИТЬ'}
                                                />
                                            </div>
                                            : <div>
                                                <AdminButton
                                                    onClick={() => setIsEditAlt(true)}
                                                    text={'ДОБАВИТЬ'}
                                                />
                                            </div>
                                        }</>
                                }
                            </div>
                            : ''
                    }
                </form>
            </div>
        </>
    )
}


export default AltpointMark