import { makeAutoObservable } from "mobx"
import { useNavigate } from "react-router-dom"


class HeaderStore {

    headerName = ''

    constructor() {
        makeAutoObservable(this);
    }


    setHeaderName = (headerName) => {
        this.headerName = headerName
    }


}

export const headerStore = new HeaderStore()
