import './styles.scss'
import { useEffect, useState } from 'react'
import AltpointInput from '../AltPointInts/AltpointInput';


const TextboxAndAlternatives = ({
    question,
    points,
    alternative,
    isEditForm,
    ...props
})=> {
    const [isDelete, setDelete] = useState(false)
    const id = (question) ? question.id : performance.now();
    let [alternativeHTML, setAlternativeHTML] = useState(<div>нет</div>)
    useEffect(()=>{
        if (alternative) {
            let point = points.find(p => p.id == alternative.target)
            setAlternativeHTML( <>
                                <AltpointInput point={point}  altId={alternative.id}/>
                              </>)
        }
    }, [isEditForm])
    
    return(
        <>
        {isDelete ? <></> :
            <div className='questionFields__container q__field__margin'>
                <div className='btn__deletepoint__container'>
                    {
                        isEditForm ? 
                            <button className='btn__deletepoint' onClick={(e)=>{
                                setDelete(true);
                            }}>x</button>
                        : ''
                    }

                    {
                        isEditForm ? <textarea name={"question_" +  id} defaultValue ={(question) ? question.name : ''} />
                        : question.name
                    } 
                </div>
                <div className='alternative__reader'>
                    {alternativeHTML}
                </div>
            </div>
        }
        </>
    )
}

export default TextboxAndAlternatives 