import { useState } from "react";
import "./checkboxAnswer.scss";
import Button from "../../buttons/Button";

const CheckboxAnswer = ({ title, data, handleInputChange }) => {
    // Состояние для хранения состояний всех чекбоксов
    const [checkedState, setCheckedState] = useState(
        data.reduce((state, item) => ({ ...state, [item.id]: false }), {})
    );

    // Функция для обработки изменения состояния чекбокса
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedState(prevState => ({ ...prevState, [name]: checked }));
    };


    const onSubmitQ = (e) => {
        e.preventDefault();
        const isAnyChecked = Object.values(checkedState).some(checked => checked);

        if (!isAnyChecked) {
            alert('Выберите хотя бы один ответ.');
            return;
        }

        let answers = [];
        data.forEach((item) => {
            let elHtml = document.getElementById(`quetion_${item.id}`)
            if (elHtml.checked) {
                answers.push({
                    name: elHtml.value,
                    question: item.id
                })
            }
        })
        handleInputChange(answers);
    }

    const handleAnswerClick = (itemId) => {
        // Cинтетическое событие для имитации изменения чекбокса
        const event = {
            target: {
                name: itemId.toString(),
                checked: !checkedState[itemId],
            }
        };
        // Вызываем функцию обработки изменения чекбокса
        handleCheckboxChange(event);
    };

    return (
        <>
            <form id={'point' + (data[0].id) ? data[0].id : data[0].id} >
                <h2 className="form__question">{title}</h2>
                <div className="form__wrappAnswer">
                    {data.map((item, i) => {
                        return (
                            <div
                                key={i}
                                className={`form__answer ${checkedState[item.id] ? 'selectedCheckbox' : ''}`}
                                onClick={() => handleAnswerClick(item.id, item.name)}>
                                <div className="form__wrappContent">
                                    <input
                                        id={`quetion_${item.id}`}
                                        value={item.name}
                                        onChange={handleCheckboxChange}
                                        checked={checkedState[item.id]}
                                        type="checkbox"
                                        name={`${item.id}`}
                                        className="form__check"
                                    />
                                    <p className='form__answerDescr'>{item.name}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Button
                    onClick={onSubmitQ}
                    addClass="buttonForward"
                    title="Вперед"
                />
            </form>
        </>
    )
}

export default CheckboxAnswer;
