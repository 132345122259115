import "./footer.scss";

const Footer = () => {
    return (
        <footer className='footer'>
            <p className="footer__copyright" >@ Copyright 2023-2024 ГКУ СО "ЦЦР СО"</p>
            <h3 className="footer__dept" >Министерство цифрового развития и связи Саратовской области</h3>
            <a className="footer__linkMap" href="https://yandex.ru/maps/-/CDuBb-KZ">г. Саратов, ул. Шелковичная, 186</a>
        </footer>
    )
}

export default Footer

