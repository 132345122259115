import { makeAutoObservable } from "mobx"
import { useNavigate } from "react-router-dom"


class User {
    isAuth = false
    isAdmin = false
    userID = 1
    userName = ''
    parentID = null
    role = ""

    constructor() {
        makeAutoObservable(this);
    }
    get getUserID() {
        return this.userID
    }

    // get getIsAuth() {
    //     return this.isAuth
    // }

    setIsAuth(isAuth) {
        this.isAuth = isAuth
        this.isAdmin = false
    }


    setRole(role) {
        this.isAdmin = role
        if (role === "Admin") {
            this.isAdmin = true
        }
    }


    async login(userName, id) {
        this.userName = userName
        this.userID = id
    }

    logout() {
        this.userName = ''
        this.isAuth = false
        this.isAdmin = false
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('department')
    }


    setUser = (user) => {
        this.userID = this.userID
        this.userName = user.userName

    }
    //////////////////////////////////////
    setParentID(parentID) {
        this.parentID = parentID
    }
}

export const userStore = new User()
