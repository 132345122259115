import { useEffect, useRef, useState } from 'react'
import SelectAlternatives from '../SelectAlternatives'
import TextboxAndAlternatives from '../TextboxAndAlternatives/input'
import './styles.scss'
import TextboxQuestion from '../TextboxQuestion/input'
import TextboxHeader from '../TextboxHeader/input'

const QuestionsFieldsTextOrNon = ({
    point,
    points, 
    isEditForm,
    ...props
}) => {
    
    const container = useRef()
    let [fields, setFields] = useState([])
    const [alternative, setAlternative] = useState([])
    let [headers, setHeaders] = useState([])

    useEffect( ()=>{
        if (point && point.questions ) {
            let newArrFields = []
            let id = -1;
            point.questions.forEach((q)=> {
                id = (q) ? q.id : performance.now()
                newArrFields.push({
                    template: <TextboxQuestion isEditForm={isEditForm} question={q}/>
                    
                })
            })
            setFields(newArrFields)
            
            let newArrHeaders = []
            point.headers.sort((a,b)=> a.num_col - b.num_col).slice(0,2).forEach((h)=> {
                newArrHeaders.push({
                    template:  <TextboxHeader nonDel={true} isEditForm={isEditForm} header={h}/>
                    
                })
            })
            setHeaders(newArrHeaders)
        }
         
    }, [isEditForm]) 

    const addQuestion = ()=> {
        setFields( [...fields, {template: <TextboxQuestion isEditForm={isEditForm} />}])
    }


    return (
        <>
            <div>
                <div className='questionFields__container'>
                    <div className="fieldName">Варианты ответов</div>
                    <div className="fieldName">Заголовки</div> 
                </div>
                <div className='questionFields__container'>
                    <div >
                        { fields.map((q) => q.template) }
                    </div>
                    <div>
                        
                        <div>
                            <div>
                                { headers.map((h) => h.template) }
                                {headers.length  < 1  ? <>
                                    <TextboxHeader nonDel={true} isEditForm={isEditForm} />
                                    <TextboxHeader nonDel={true} isEditForm={isEditForm} />
                                </> : ''}
                                {headers.length  == 1 ? <>
                                    <TextboxHeader nonDel={true} isEditForm={isEditForm} />
                                </> : ''}   
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isEditForm ? <div className='btn__adQduestion' onClick={addQuestion}> + </div> : ''
                }
                
            </div>
        </>
    )
}

export default QuestionsFieldsTextOrNon