import React, { useState } from 'react';
import "./adaptiveTable.scss";
import Button from '../../buttons/Button';

const AdaptiveTable = ({
    title,
    data,
    dataHeader,
    handleInputChange,
    currentQuestion
}) => {
    const [selectedIndexes, setSelectedIndexes] = useState({});

    const handleWrapperClick = (vQuestionId, headerIndex) => {
        setSelectedIndexes(prev => ({ ...prev, [vQuestionId]: headerIndex }));
    };

    const onSubmitQ = (e) => {
        e.preventDefault();
        if (Object.keys(selectedIndexes).length !== data.length) {
            alert("Выберите один ответ по каждой строке.");
            return;
        }

        let answers = [];
        for (let key in selectedIndexes) {
            answers.push({
                name: selectedIndexes[key] + 1,
                question: key
            });
        }
        handleInputChange(answers);
    };


    return (
        <>
            <form id={'point' + data[0].id}>
                <h2 className="form__question">{title}</h2>
                <div className="adaptiveTable">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                {dataHeader.map((item, i) => (
                                    <th key={i}>{item.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((vQuestion, vIndex) => (
                                <tr key={vIndex}>
                                    <td className='adaptiveTable__vQuestion' >{vQuestion.name}</td>
                                    {dataHeader.map((_, hIndex) => (
                                        <td data-label={_.name}
                                            className='adaptiveTable__wrappCheck'
                                            key={hIndex}
                                            onClick={() => handleWrapperClick(vQuestion.id, hIndex)}
                                        >
                                            <input
                                                name={`${currentQuestion.id}-${vQuestion.id}`}
                                                className='adaptiveTable__check'
                                                type="radio"
                                                checked={selectedIndexes[vQuestion.id] === hIndex}
                                                onChange={() => handleWrapperClick(vQuestion.id, hIndex)}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Button
                    onClick={onSubmitQ}
                    addClass="buttonForward"
                    title="Вперед"
                />
            </form>
        </>
    );
};

export default AdaptiveTable;
