import './styles.scss'

const AdminButton = ({
    text,
    classes,
    ...props
})=>{
    return (
        <div className={'adminButton ' + ((classes) ? classes : '')}  {...props}>{text}</div>
    )
}

export default AdminButton