import "./button.scss";
const Button = ({ onClick, addClass, title }) => {
    return (
        <button onClick={onClick} className={`button ${addClass}`}>
            {title}
        </button>
    );
};

export default Button;


