import { useState } from "react";
import "../checkboxAndText/checkboxAndText.scss";
import Button from "../../buttons/Button";


const RadioAndText = ({ title, data, handleInputChange, handleRadioChange, currentQuestion }) => {
    // Состояние для хранения состояний всех чекбоксов
    const [checkedState, setCheckedState] = useState(
        data.reduce((state, item) => ({ ...state, [item.id]: false }), {})
    );

    const isAnyChecked = Object.values(checkedState).some(checked => checked);

    // Функция для обработки изменения состояния чекбокса
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedState(prevState => ({ ...prevState, [name]: checked }));
    };

    const onSubmitQ = (e) => {
        e.preventDefault();
        const textArea = document.getElementById("checkboxFreeAnswer")
        if (textArea && textArea.value.length === 0 && !isAnyChecked) {
            alert('Выберите хотя бы один ответ.');
            return;
        }

        let answers = [];
        data.forEach((item) => {
            let elHtml = document.getElementById(`quetion_${item.id}`)
            if (elHtml && elHtml.checked) {
                answers.push({
                    name: elHtml.value,
                    question: item.id
                })
            }
        })

        for (let i = 0; i < data.length; i++) {
            if (textArea && textArea.value.trim().length > 0) {
                const freeAnswer = {
                    name: textArea.value,
                    question: data[data.length - 1].id
                };
                answers.push(freeAnswer);
                break;
            }
        }

        handleInputChange(answers);
    }

    const handleAnswerClick = (name) => {
        const textArea = document.getElementById("checkboxFreeAnswer")
        setCheckedState(prevState => {
            const newState = { ...prevState };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[name] = true;
            return newState;
        });

        if (isAnyChecked) {
            textArea.value = "";
        }

        // Вызываем handleRadioChange для обновления родительского состояния
        handleRadioChange({ target: { name: `point${currentQuestion.id}`, value: name } });
        const checkbox = document.getElementById(`quetion_${name}`);

        checkbox.click();
    };

    const checkTextArea = (e) => {
        const inputs = document.querySelectorAll('.form__check');
        const textArea = document.querySelector('#checkboxFreeAnswer');

        if (e[0] === '\n') {
            alert("Ответ не может начинатся с переноса строки")
            textArea.value = "";
        }

        if (e == " " || e == "  " || e == "  " || e == "    " || e == "     " || e == "     " || e == "     " || e == "     " || e == "     ") {
            alert("Ответ не может состоять исключительно из пробельных символов")
            textArea.value = ""
        }

        if (e.length > 100) {
            textArea.value = textArea.value.substring(0, 96) + '...';
            alert("Длинна ответа не может быть больше 100 символов")

        }

        if (e.length > 0) {
            inputs.forEach(input => {
                input.checked = false
            })
        }
    }

    return (
        <>
            <form id={'point' + data[0].id} >
                <h2 className="form__question">{title}</h2>
                <div className="form__wrappAnswer">
                    {data.map((item, i) => {
                        return data.length - 1 === i ?
                            <textarea
                                key={i}
                                onChange={(e) => checkTextArea(e.target.value)}
                                id="checkboxFreeAnswer"
                                name="checkboxFreeAnswer"
                                placeholder="Свой ответ"
                                className="form__yourAnswer"></textarea>
                            :
                            <div
                                onClick={() => handleAnswerClick(item.id.toString())}
                                key={i}
                                className={`form__answer ${checkedState[item.id] ? 'selectedCheckbox' : ''}`}>
                                <input
                                    id={`quetion_${item.id}`}
                                    value={item.name}
                                    onChange={handleCheckboxChange}
                                    checked={checkedState[item.id]}
                                    type="radio"
                                    name={`${item.id}`}
                                    className="form__check" />
                                <p className='form__answerDescr'>{item.name}</p>
                            </div>
                    })}
                </div>
                <Button
                    onClick={onSubmitQ}
                    addClass="buttonForward"
                    title="Вперед"
                />
            </form>
        </>
    )
}

export default RadioAndText;
