import { useEffect, useRef, useState } from 'react'
import SelectAlternatives from '../SelectAlternatives'
import TextboxAndAlternatives from '../TextboxAndAlternatives/input'
import './styles.scss'
import TextboxQuestion from '../TextboxQuestion/input'
import TextboxHeader from '../TextboxHeader/input'

const QuestionsFieldsTable = ({
    point,
    points, 
    isEditForm,
    ...props
}) => {
    let [fields, setFields] = useState([])
    let [headers, setHeaders] = useState([])

    let names = {}
    points.forEach(point => {
        let strs = point.name.split(' ')
        let name = ''
        let i = 0
        while (name.length < 50 && strs.length > i) {
            name += strs[i] + ' '
            i++
        }

        if (strs.length > i) name += '...'
        names[point.id] = name
        
    });

    useEffect(()=>{
        if (point && point.questions ) {
            let newArrFields = []
            let id = -1;
            point.questions.sort((a,b)=> a.number - b.number).forEach((q)=> {
                
                id = (q) ? q.id : performance.now()
                newArrFields.push({
                    template: <TextboxQuestion isEditForm={isEditForm} question={q}/>
                    
                })
            })

            setFields(newArrFields)

            let newArrHeaders = []
            point.headers.forEach((h)=> {
                
                id = (h) ? h.id : performance.now()
                newArrHeaders.push({
                    template: <TextboxHeader isEditForm={isEditForm} header={h}/>
                })
            })
            setHeaders(newArrHeaders)
        }
         
    }, [isEditForm]) 

    const addQuestion = ()=> {
        setFields( [...fields, {template: <TextboxQuestion isEditForm={isEditForm}/>}])
    }

    const addHeader = ()=> {
        setHeaders( [...headers, {template: <TextboxHeader isEditForm={isEditForm} />}])
    }

    return (
        <>
            <div>
                <div className='questionFields__container'>
                    <div className="fieldName">Разделы</div>
                    <div className="fieldName">Заголовки</div>
                </div>
                <div className='questionFields__container'>
                    <div>
                        <div >
                            { fields.map((q) => q.template) }
                        </div>
                        {isEditForm ? <div className='btn__adQduestion' onClick={addQuestion}> +</div> : ''}
                    </div>
                    <div>
                        <div>
                            { headers.map((h) => h.template) }
                        </div>
                        {isEditForm ? <div className='btn__adQduestion' onClick={addHeader}>+</div> : ''}
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default QuestionsFieldsTable