import './styles.scss'

const CompleteButton = (
    props
)=>{
    return (
        <div className='markButton completeButton' {...props}></div>
    )
}

export default CompleteButton