import { useEffect, useState } from "react"


const NumberOfPoint = ({
    currentNumber,
    count,
    pointId,
    isEditForm,
    ...props
})=> {

    return <>
    <div >
        <span>Номер: </span>
        { 
            isEditForm ?
            <input type="number"
                name="number" 
                defaultValue={currentNumber ? currentNumber : count+1}  
                max={pointId ? count : count+1}
                min={1}
                style={{display:'inline', width: 'fit-content'}}
            />
            : currentNumber
        }
    </div>
    </>
}

export default NumberOfPoint