

const SelectQuestions = ({
    questions,
    alternative,
    id,
    ...props
}) => {
    let names = {}
    questions.forEach(q => {
        let strs = q.name.split(' ')
        let name = ''
        let i = 0
        while (name.length < 50 && strs.length > i) {
            name += strs[i] + ' '
            i++
        }
        if (strs.length > i) name += '...'
        names[q.id] = name
    });

    return (
        <>
            <select name={"question_" + id} {...props}>
                <option value={-1}>нет</option>
                {questions.map((q)=> <option value={q.id} >{q.number} {names[q.id] }</option> )}
            </select >
        </>
    )
}   

export default SelectQuestions