const endForm = () => {
    const formTitle = document.querySelector(".form__title");

    if (formTitle) {
        formTitle.remove();
    }

    const div = document.createElement("div");
    div.style.top = "40%";
    div.style.left = "50%";
    div.style.borderRadius = "5px";
    div.textContent = "Спасибо за участие в опросе.";
    div.style.position = "absolute";
    div.style.transform = "translate(-50%, 50%)"
    div.style.fontSize = "36px";
    div.style.minWidth = "280px";
    div.style.textAlign = "center";
    const form = document.querySelector('.form');
    form.appendChild(div);
}

export default endForm;