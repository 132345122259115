import './styles.scss'
import AdminButton from "../../../../components/AdminButton/AdminButton";
import SelectAlternatives from "../SelectAlternatives"
import SelectQuestions from '../SelectQuestions';
import { useRef, useState } from 'react';
import { Store } from 'react-notifications-component';


const ModalAltRPoint = ({
    questions,
    points,
    alternatives,
    closeModal,
    saveAlternative,
    formId,
    pointId,
    ...props
}) => {

    const formHTML = useRef();
    const [message, setMessage] = useState('')

    const addAlternate = (e) => {
        let err = false;
        let data = new FormData(formHTML.current)
        let newData = {}
        setMessage('')
        for (let field of data) {
            let name = field[0].split('_')[0]
            newData[name] = field[1];
            if (field[1] == -1) {
                err = true;
                setMessage('Выберете значение')
                break;
            }
        }

        let countSame = 0
        let count = 0


        for (let key in newData) {
            count++
            if (key == 'alternative')
                for (let i in alternatives)
                    if (alternatives[i].target == newData[key]) {
                        countSame++
                        break
                    } else if (newData[key] == pointId) {
                        err = true;
                        setMessage('Вопрос не может ссылаться сам на себя')
                        break
                    }

            if (key == 'question') {
                for (let i in alternatives)
                    if (alternatives[i].mrk_qstn == newData[key]) {
                        countSame++
                        err = true;
                        setMessage('У этого вопроса уже есть связь')
                        break
                    }
            }

        }
        if (count == countSame) {
            err = true;
            setMessage('Такая связь уже существует')
        }

        if (!err) {
            setMessage('')
            saveAlternative(newData)
            // closeModal(false)
        }

    }


    return (
        <div className='fon__dark' onClick={(e) => { if (e.currentTarget == e.target) closeModal(false) }}>

            <div className='modalWindow'>
                <form ref={formHTML}>
                    <div>С какого ответа</div>
                    <SelectQuestions questions={questions} id={pointId} />
                    <div>На какой вопрос</div>
                    <SelectAlternatives points={points} id={formId} />
                    {(message) ? <div style={{ color: 'red' }}>{message}</div> : ''}
                    <AdminButton
                        onClick={addAlternate}
                        text={'Добавить'}
                    />
                </form>
                {/* {alternativeHTML} */}
            </div>
        </div>
    )
}

export default ModalAltRPoint