import Button from "../../buttons/Button";
import "./numberAnswer.scss";


//////////////////////// Mark //////////////////////////////
const NumberAnswer = ({ title, data, handleInputChange, currentQuestion }) => {

    const validNumber = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^1-5]/g, '');
        if (numericValue.length > 1) {
            e.target.value = numericValue.slice(0, 1);
        } else {
            e.target.value = numericValue;
        }
    }

    const onSubmitQ = (e) => {
        e.preventDefault();

        const isChecked = document.querySelectorAll(`.form__numberInput`);
        let isAllFilled = true; // Переменная для отслеживания заполненности всех полей
        let showAlert = false; // Флаг для отображения предупреждения

        isChecked.forEach((item) => {
            if (!item.value.length) {
                isAllFilled = false;
                showAlert = true;
            }
        });

        if (showAlert) {
            alert('Все поля должны быть заполнены.');
        }

        if (!isAllFilled) {
            return;
        }

        let answers = [];
        data.forEach((item) => {
            let elHtml = document.getElementById(`quetion_${item.id}`);
            if (elHtml.value) {
                answers.push({
                    name: elHtml.value,
                    question: item.id,
                    pointId: currentQuestion.id
                });
            }
        });

        handleInputChange(answers);
    }


    return (
        <>
            <form id={'point' + data[0].id} >
                <h2 className="form__question">{title}</h2>
                <div className="form__number">
                    <table>
                        <tbody>
                            {data.map((question, index) => {
                                return <tr key={index}>
                                    <td>{question.name}</td>
                                    <td>
                                        <input
                                            id={`quetion_${question.id}`}
                                            onChange={(e) => {
                                                validNumber(e)
                                            }}
                                            className="form__numberInput"
                                            type="text"
                                            name={`${question.id}`}
                                        />
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <Button
                    onClick={onSubmitQ}
                    addClass="buttonForward"
                    title="Вперед"
                />
            </form>
        </>
    )
}

export default NumberAnswer

