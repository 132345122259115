import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './rest.css';
import App from './App';
import { userStore } from './store/user'

export const UserContext = createContext(userStore)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


